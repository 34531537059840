import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

import { ContentfulProject } from "../../graphql-types";

interface Props {
  project: ContentfulProject;
}

const ImageContainer = styled.div`
  background-color: #2d0e2f;
  padding-bottom: 100%;
  position: relative;
  border: solid 1px #eee;
`;

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: contain;
  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: contain;
  }
`;

const Title = styled.h3`
  font-family: Fira Sans;
  text-transform: uppercase;
  margin: 10px 0 0 0;
  color: #d66569;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

const Hover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: contain;
    &:last-child {
      z-index: 1;
      opacity: 0;
    }
  }
  &:hover {
    .gatsby-image-wrapper {
      &:last-child {
        opacity: 1;
      }
    }
  }
`;

export default ({ project }: Props) => (
  <Link to={`/project/${project.slug}`}>
    <ImageContainer>
      {project.previewImage && (
        <Inner>
          <Hover>
            <Img fluid={project.previewImage.fluid} />
            {project.gifPreview && <Img fluid={project.gifPreview.fluid} />}
          </Hover>
        </Inner>
      )}
    </ImageContainer>
    <Title>{project.title}</Title>
  </Link>
);
