import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import ProjectCard from "../components/project-card";
import { Query } from "../graphql-types";
import PageContainer from "../components/page-container";
import AnimatedList from "../components/animated-list";
import PageHeader from "../components/page-header";
import Title from "../components/title";
import SEO from "../components/seo";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: minmax(90px, auto);
  grid-gap: 50px;
`;

interface Props {
  data: Query;
}

export default ({ data }: Props) => {
  if (data && data.allContentfulProjectsPage) {
    const { edges } = data.allContentfulProjectsPage;
    const [page] = edges.map(e => e.node);
    return (
      <Layout>
        <SEO data={page.seo} />
        <PageContainer>
          <PageHeader>
            <Title>{page.title}</Title>
          </PageHeader>

          <Container>
            <AnimatedList items={page.projects} keyFinder={p => p.id}>
              {project => <ProjectCard project={project} />}
            </AnimatedList>
          </Container>
        </PageContainer>
      </Layout>
    );
  }

  return null;
};

export const query = graphql`
  {
    allContentfulProjectsPage {
      edges {
        node {
          title
          seo {
            ...SEO
          }
          projects {
            id
            slug
            title
            gifPreview {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
            previewImage {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;
